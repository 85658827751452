import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Blog from '../components/Blog'
import PageTitle from '../components/PageTitle'
import { connect } from 'react-redux'
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"


const BlogPage = ({ blog }) => {
  
  const { t } = useTranslation()
    // if (!blog.length) {
    //     return (
    //         <div style={{backgroundColor:bgColor}}>
    //             <Layout 
    //                 header={t("header", { returnObjects: true })}
    //                 footer={t("footer", { returnObjects: true })}
    //                 bgColor={bgColor}
    //                 page='blog'
    //                 dark
    //             >
    //                 <Seo title="Notícias" />
    //                 <div style={{
    //                     height:'65vh',
    //                     display:'flex',
    //                     justifyContent:'center',
    //                     alignItems:'center'
    //                 }}>
    //                     <h1 style={{
    //                         textAlign:'center',
    //                         textTransform:'uppercase',
    //                         color:'#eeefed',
    //                         fontWeight:'200',
    //                         fontSize:'3rem',
    //                         zIndex:'2'
    //                     }}>
    //                         Não existem notícias.
    //                     </h1>
    //                 </div>
    //             </Layout>
    //         </div>
    //     )
    // }

    return (
        <Layout>
            <Seo title="NOTÍCIAS"/>
            <PageTitle
                title={t("home", { returnObjects: true }).blog.title}
                subtitle='Aenean varius lorem at turpis pellentesque.'
            />
            <Blog
                posts={blog}
                data={t("home", { returnObjects: true })}
            />
        </Layout>
    )
}

export default connect(state => ({
    blog: state.app.blog
}), null)(BlogPage)

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`