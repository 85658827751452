import * as React from "react"
import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { color, media } from './Styles'
import Button from './Button'

const Blog = ({ posts, data }) => {
    return(
        <BlogStyled>
            <div className='wrapper'>
                {/* NOTICIAS DESTACADAS */}
                <div className='feature-container'> 
                    <div className='title'>
                        <h3 dangerouslySetInnerHTML={{ __html: data.blog.text[0]}}></h3>
                    </div>
                    {posts.map(post => {
                        if (post.isSticky) { // SE FOR DESTACADO
                            const title = post.title
                            const image = {
                                image: getImage(post.featuredImage?.node?.localFile),
                                alt: post.featuredImage?.node?.altText || '',
                            }

                            return(
                                <div className='news' key={post.uri}>
                                    <Link to={'/blog' + post.uri} itemProp='url' className='news-content'>
                                        {image?.image &&
                                            <GatsbyImage image={image.image} alt={image.alt || ''} className='image'/>
                                        }
                                        <div className='content'>
                                            <span className='date'>{post.date}</span>
                                            <h2>{parse(title)}</h2>
                                            <span className='description'>{parse(post.excerpt)}</span>
                                            <div className='button-container'>
                                                <Button
                                                    to={'/blog' + post.uri}
                                                    text={data.bottom.title[0]}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        }      
                    })}            
                </div>
                {/* --- */}
                
                {/* ULTIMAS NOTICIAS */}
                <div className='news-container'>
                    <div className='title'>
                        <h3 dangerouslySetInnerHTML={{ __html: data.blog.text[1]}}></h3>
                    </div>
                    <>
                        {posts.map(post => {
                            const title = post.title
                            const image = {
                                image: getImage(post.featuredImage?.node?.localFile),
                                alt: post.featuredImage?.node?.altText || '',
                            }

                            return(
                                <div className='news' key={post.uri}>
                                    <Link to={'/blog' + post.uri} itemProp='url' className='news-content'>
                                        {image?.image &&
                                            <GatsbyImage image={image.image} alt={image.alt || ''} className='image'/>
                                        }
                                        <div className='content'>
                                            <span className='date'>{post.date}</span>
                                            <h2>{parse(title)}</h2>
                                            <span className='description'>{parse(post.excerpt)}</span>
                                            <div className='button-container'>
                                                <Button
                                                    to={'/blog' + post.uri}
                                                    text={data.bottom.title[0]}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </>
                </div>
                {/* --- */}
            </div>
        </BlogStyled>
    )
}

export default Blog

const BlogStyled = styled.div`
    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding: clamp(30px, 5vw, 60px) 5%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 5%;

        ${media.l`
            grid-template-columns: 1fr;
            gap:10rem;
        `}

        .image{
            height:500px;

            ${media.m`
                height:225px;
            `}
        }

        .title{
            width:100%;
            border-bottom: 3px solid ${color.yellow};
            padding-bottom:1rem;

            h3{
                font-weight:600;
                text-transform: uppercase;
                font-size: 1.5rem;
                letter-spacing: 0.5rem;
            }
        }

        .feature-container, .news-container{
            display:flex;
            flex-direction:column;
            gap:5rem;
        }

        .news{
            background-color:#e2e2e2;

            .content{
                position:relative;
                padding:5%;
                display: flex;
                flex-direction: column;
                justify-content:center;
                gap: 1rem;

                .date{
                    font-family: 'Roboto',serif;
                    font-weight: 600;
                    letter-spacing: 0.1rem;
                    color: #a8a8a8;
                }

                h2{
                    font-weight: 700;
                    text-transform: uppercase;
                }

                .description{
                    font-weight:500;
                    color:#5c5a5a;
                    margin-bottom:1rem;
                    
                    p {
                        line-height:1.5rem;
                    }
                }

                .button-container{
                    position: absolute;
                    bottom: -13%;
                    right: 5%;
                }
            }
        }

        .news-container{
            .news{
                background-color:transparent;

                .news-content{
                    display: grid;
                    grid-template-columns: repeat(2,1fr);

                    ${media.m`
                        grid-template-columns: 1fr;
                    `}

                    .content{
                        ${media.m`
                            padding:2rem 0 0 0;
                        `}
                    }

                    .image{
                        height:350px;

                        ${media.m`
                            height:225px;
                        `}
                    }

                    .button-container{
                        position: relative;
                        bottom:0;
                        right:0;
                    }
                }
            }
        }
    }
`